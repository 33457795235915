import React from "react";
import Logo from "./Logo";
import NavbarLink from "./NavbarLink";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import Bouton from "../Bouton";

const NavWeb = ({ isNavbarOpen, setIsNavbarOpen }) => {
  return (
    <div className="mx-auto w-[90%] flex  flex-row justify-between py-3">
      <Logo />
      <div className="hidden md:flex gap-5 items-center text-[#333333]">
        <NavbarLink />
      </div>
      <div className="flex items-center gap-4 ">
        <NavLink
          to="/salle"
          className=" items-center hidden md:flex py-1 px-4 text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-full" // Augmenté légèrement la largeur
        >
          <img
            src="../img/mouse-pointer.png"
            alt="icon-recherche"
            className="w-[20px]"
          />
          <span className="text-md">Recherche</span>
        </NavLink>
        <NavLink
          to="#"
          onClick={(e) => {
            e.preventDefault();
            window.open(
              "https://calendar.app.google/kGhsrh5DLK98EW6b7",
              "_blank",
              "noopener,noreferrer"
            );
          }}
        >
          <Bouton
            label="Prenez rendez-vous"
            className=" bg-main-red hidden md:block text-white  font-semibold rounded-full py-2 px-3 text-sm "
          />
        </NavLink>
        {!isNavbarOpen && (
          <FontAwesomeIcon
            onClick={() => setIsNavbarOpen(!isNavbarOpen)}
            icon={faBars}
            className="curseur-pointer md:hidden block text-xl"
          />
        )}
      </div>
    </div>
  );
};

export default NavWeb;
