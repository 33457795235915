import React from "react";

const dataServices = [
  {
    icon: (
      <svg
        width="40"
        height="41"
        viewBox="0 0 40 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5003 7.07878V17.3988L15.8337 15.4121L19.167 17.3988V7.07878H12.5003ZM29.167 36.5654C27.3103 36.5654 25.7353 35.9188 24.442 34.6254C23.1475 33.3321 22.5003 31.7566 22.5003 29.8988C22.5003 28.0432 23.1525 26.4682 24.457 25.1738C25.7614 23.8793 27.3848 23.2321 29.327 23.2321C31.0981 23.2321 32.6259 23.8793 33.9103 25.1738C35.1948 26.4682 35.8359 28.0432 35.8337 29.8988C35.8337 31.7566 35.187 33.3321 33.8937 34.6254C32.5992 35.9188 31.0237 36.5654 29.167 36.5654ZM27.7253 33.0404L32.2753 29.8354L27.7253 26.6304V33.0404ZM19.167 29.8971C19.167 30.886 19.3064 31.8449 19.5853 32.7738C19.8642 33.7038 20.2698 34.5832 20.802 35.4121H9.36033C8.61921 35.4121 7.98533 35.1482 7.45866 34.6204C6.93199 34.0927 6.6681 33.4599 6.66699 32.7221V8.10378C6.66699 7.36378 6.93088 6.73044 7.45866 6.20378C7.98644 5.67711 8.61977 5.41322 9.35866 5.41211H27.3087C28.0487 5.41211 28.682 5.676 29.2087 6.20378C29.7353 6.73155 29.9992 7.36489 30.0003 8.10378V19.9504C29.8881 19.9438 29.7753 19.9393 29.662 19.9371C29.5498 19.9338 29.4375 19.9321 29.3253 19.9321C26.5142 19.9321 24.1181 20.8999 22.137 22.8354C20.1559 24.771 19.1659 27.126 19.167 29.8971Z"
          fill="black"
        />
      </svg>
    ),
    text: "+25",
    content: "établissement sportif",
    bgClass: "bg1",
  },
  {
    icon: (
      <svg
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21 7.41211C22.9425 7.41211 24.5 8.96961 24.5 10.9121C24.5 12.8546 22.9425 14.4121 21 14.4121C19.0575 14.4121 17.5 12.8546 17.5 10.9121C17.5 8.96961 19.075 7.41211 21 7.41211ZM36.75 28.4121V24.9121C32.83 24.9121 29.47 23.2321 26.95 20.2221L24.605 17.4221C24.2794 17.0274 23.8705 16.7098 23.4075 16.4919C22.9446 16.2741 22.4391 16.1614 21.9275 16.1621H20.125C19.0575 16.1621 18.0775 16.6171 17.4125 17.4221L15.0675 20.2221C12.53 23.2321 9.17 24.9121 5.25 24.9121V28.4121C10.0975 28.4121 14.3325 26.3646 17.5 22.7246V26.6621L10.71 29.3746C9.5375 29.8471 8.75 31.0371 8.75 32.2796C8.75 34.0121 10.15 35.4121 11.8825 35.4121H15.75V34.5371C15.75 33.3768 16.2109 32.264 17.0314 31.4435C17.8519 30.623 18.9647 30.1621 20.125 30.1621H25.375C25.865 30.1621 26.25 30.5471 26.25 31.0371C26.25 31.5271 25.865 31.9121 25.375 31.9121H20.125C18.6725 31.9121 17.5 33.0846 17.5 34.5371V35.4121H30.1175C31.85 35.4121 33.25 34.0121 33.25 32.2796C33.25 31.0371 32.4625 29.8471 31.29 29.3746L24.5 26.6621V22.7246C27.6675 26.3646 31.9025 28.4121 36.75 28.4121Z"
          fill="black"
        />
      </svg>
    ),
    text: "+30",
    content: "activité sportives",
    bgClass: "bg2",
  },
  {
    icon: (
      <svg
        width="42"
        height="43"
        viewBox="0 0 42 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1384_7886)">
          <path
            d="M21 3.91211C30.6652 3.91211 38.5 11.7469 38.5 21.4121C38.5 31.0774 30.6652 38.9121 21 38.9121C11.3348 38.9121 3.5 31.0774 3.5 21.4121C3.5 11.7469 11.3348 3.91211 21 3.91211ZM26.964 8.74211L22.029 12.3279C21.7611 12.5226 21.4434 12.6374 21.1129 12.6588C20.7823 12.6801 20.4525 12.6073 20.1618 12.4486L19.971 12.3279L15.036 8.74211C13.6181 9.41084 12.3226 10.313 11.2035 11.4109L10.794 11.8291L12.6788 17.6269C12.7812 17.9418 12.7922 18.2794 12.7104 18.6003C12.6286 18.9212 12.4574 19.2123 12.2168 19.4399L12.0417 19.5834L7.1085 23.1674C7.308 24.7599 7.7735 26.2666 8.46125 27.6456L8.729 28.1566H14.8277C15.1625 28.1564 15.4904 28.2523 15.7723 28.4328C16.0543 28.6133 16.2786 28.871 16.4185 29.1751L16.492 29.3659L18.3785 35.1671C19.8964 35.4558 21.4515 35.4913 22.981 35.2721L23.6232 35.1671L25.508 29.3659C25.6114 29.0477 25.8039 28.7659 26.0625 28.5537C26.3212 28.3416 26.6353 28.208 26.9675 28.1689L27.1723 28.1566H33.271C34.0238 26.7871 34.5419 25.3012 34.804 23.7606L34.8915 23.1674L29.9565 19.5834C29.6884 19.3886 29.4811 19.1217 29.3588 18.8138C29.2364 18.5059 29.204 18.1695 29.2652 17.8439L29.3212 17.6269L31.206 11.8291C30.1349 10.6865 28.8796 9.73177 27.4925 9.00461L26.964 8.74211ZM19.971 14.7464C20.2389 14.5516 20.5566 14.4368 20.8871 14.4155C21.2177 14.3941 21.5475 14.467 21.8382 14.6256L22.029 14.7464L27.0217 18.3741C27.2895 18.5687 27.4966 18.8353 27.619 19.1429C27.7413 19.4505 27.7739 19.7865 27.713 20.1119L27.657 20.3306L25.7495 26.2001C25.6461 26.5183 25.4536 26.8001 25.195 27.0123C24.9363 27.2244 24.6222 27.358 24.29 27.3971L24.0852 27.4094H17.9148C17.58 27.4095 17.2521 27.3137 16.9702 27.1332C16.6882 26.9526 16.4639 26.695 16.324 26.3909L16.2505 26.2001L14.343 20.3306C14.2406 20.0157 14.2296 19.6781 14.3114 19.3572C14.3932 19.0363 14.5643 18.7451 14.805 18.5176L14.98 18.3741L19.971 14.7464Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1384_7886">
            <rect
              width="42"
              height="42"
              fill="white"
              transform="translate(0 0.412109)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    text: "+18",
    content: "partenaires",
    bgClass: "bg3",
  },
];

export const CardService = () => {
  
  return (
    <div className=" py-9 px-0 md:px-16 lg:px-24 pb-8 mt-8 w-[100%] bg-search-dots bg-cover max mb-4 flex flex-col max-lg:bg-repeat-y ">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[30px] w-[80%] mx-auto">
        {dataServices.map((dataservice, index) => (
          <div
            key={index}
            className="w rounded-[12px] flex flex-col bg-white shadow-search-one max-lg:mx-auto max-md:w-[100%]"
          >
            <div className={`tab h-10 ${dataservice.bgClass} rounded-t-[12px]`}>
              <div className="w-[45px] h-[45px] bg-white rounded-full ml-[76%] mt-[5%] flex justify-center items-center">
                {dataservice.icon}
              </div>
            </div>
            <div className="flex flex-col  mt-[7.10%] ">
              <div className="text-[30px] leading-h1 font-arial font-bold ml-[5.3%]">
                {dataservice.text}
              </div>
              <p className="text-h5 text-sm lg:text-[18px] mb-4 leading-h4 font-quicksand font-normal px-4 ">
                {dataservice.content}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
