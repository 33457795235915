import React from "react";
import { NavLink } from "react-router-dom";

const Logo = () => {
  return (
    <NavLink to="/" className="flex items-center">
      <img
        className="w-[48px] h-[38px]"
        src="../img/logo.png"
        alt="Logo de ketket"
        loading="lazy"
      />
      <img
        src="../img/letterKetKet.png"
        className="w-[120px] h-[23px]"
        alt="Ket ket"
        loading="lazy"
      />
    </NavLink>
  );
};

export default Logo;
