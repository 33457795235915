import React from "react";
import { Link } from "react-router-dom";

const LeftSection = () => {
  return (
    <div className="hero-text-container col-span-1 w flex-col">
      <div className="hero-text bg-white">
        <div
          className="hero-title bg-hero-texture max-sm:mt-5  pb-0 px-3"
          style={{
            backgroundSize: "400px",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h1 className="font-Arial font-semibold text-[60px] max-sm:text-[40px] text-main-blue scroll-py-32 max-sm:leading-[50px]">
            Wellness Elevated <br /> by Partnership
          </h1>
        </div>
        <h2 className="text-2xl px-3 text-main-red font-medium ">
          Devenir un partenaire de Ket Ket
        </h2>
      </div>

      <div className="hero-subtitle py-2 px-3 pt-6 max-sm:py-0">
        <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold my-2  max-sm:text-[13px] max-sm:my-2">
          S'associer avec Ket Ket vous permet d'améliorer le bien-être de vos
          employés grâce à un accès facilité aux installations sportives
          amateurs.
        </p>

        <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold max-sm:text-[13px]  my-4 max-sm:my-2">
          Rejoignez-nous pour transformer le bien-être au travail et créer un
          cadre propice à la productivité et à l'épanouissement des talents.
        </p>
      </div>

      <Link
        to={"devenir-partenaire"}
        className="flex w-[250px] mt-3 px-3 max-sm:mb-0 max-sm:mt-4 gap-4 "
      >
        <button
          className={
            "bg-main-blue text-white text-h6 text-sm font-quicksand font-semibold  py-2 rounded-lg flex items-center px-4 gap-2 "
          }
        >
          <img src="./users.png" alt="user-image" className="w-[20px]" />{" "}
          Devenons partenaires
        </button>
      </Link>
    </div>
  );
};

export default LeftSection;
