import React, { createContext, useState } from "react";
import useContacts from "../../hooks/recherche/useContacts";
import { useAddContactMutation } from "../../../api/contactpartenaire/hooks";
import { toast } from "react-toastify";

export const EntrepriseContext = createContext();

const EntrepriseContextProvider = ({ children }) => {
  const { setShowModal, dateFormatte } = useContacts();
  const [numero, setNumero] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormState] = useState({
    adresse_id: 1,
    date_creation: dateFormatte,
    date_modification: dateFormatte,
    description_etablissement: "",
    effectif: "",
    nom: "",
    nom_etablissement: "",
    numero: "",
    mail: "",
    mail_responsable: "",
    rendez_vous: "",
    prenom: "",
    type: "",
  });
  const { mutateAsync: addNewContact } = useAddContactMutation();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validateForm = () => {
    if (
      !formState.nom ||
      // !formState.mail ||
      !numero ||
      !formState.prenom ||
      // !formState.mail_responsable ||
      !formState.nom_etablissement ||
      !formState.effectif ||
      formState.effectif === "select" ||
      !formState.description_etablissement
    ) {
      toast.error("Veuillez remplir tous les champs obligatoires.");
      return false;
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // if (!emailRegex.test(formState.mail)) {
    //   toast.error("L'adresse e-mail est invalide.");
    //   return false;
    // }

    // if (!emailRegex.test(formState.mail_responsable)) {
    //   toast.error("L'adresse e-mail du responsable est invalide.");
    //   return false;
    // }
    return true;
  };
  const resetForm = () => {
    setShowModal(false);
    setFormState({
      adresse_id: 1,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      description_etablissement: "",
      effectif: "",
      nom: "",
      nom_etablissement: "",
      numero: "",
      mail: "",
      mail_responsable: "",
      rendez_vous: "",
      prenom: "",
      type: "",
    });
    setNumero("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const payload = {
      ...formState,
      numero,
      date_creation: dateFormatte,
      date_modification: dateFormatte,
      type: "Entreprise",
    };

    try {
      setIsLoading(true);
      const response = await addNewContact(payload);
      if (response.data) {
        resetForm();
        toast.success("Contact ajouté avec succès !");
      } else {
        toast.error("Une erreur s'est produite.");
        console.log();
        
      }
    } catch (error) {
      console.log(error);
      toast.error(
        error.response?.data?.message || "Une erreur inattendue est survenue."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    numero,
    setNumero,
    isLoading,
    setIsLoading,
    formState,
    setFormState,
    handleChange,
    handleSubmit,
  };

  return (
    <EntrepriseContext.Provider value={value}>
      {children}
    </EntrepriseContext.Provider>
  );
};

export default EntrepriseContextProvider;
