import React, { useEffect, useState } from "react";
import Search_main from "../components/components_search_page/search_main";
import Map from "../components/components_search_page/map";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ModalSearch } from "../components/components_search_page/modalSearch";
import useRecherche from "../utils/hooks/recherche/useRecherche";
import Logo from "../components/recherche/logo";
import { useLocationsQuery } from "../api/partenaireSport/hooks";
import { ScaleLoader } from "react-spinners";
import Loader from "../components/partenaires/Loader";
import ScrollToTop from "../components/ScrollToTop";

export default function SearchPage(handleCloseViewModal) {
  const {
    showSearchModal,
    checkboxState,
    setCheckboxState,
    checkboxState_Activity,
    searchModalRef,
    viewModalRef,
    selectedEnterprise,
    setSelectedEnterprise,
    showViewModal,
    onCheckboxChange_Activity,
    onCheckboxChange,
    toggleCheckboxState,
    handleOpenSearchModal,
    handleCloseSearchModal,
  } = useRecherche();
  const { data: partenaires = [], error, isLoading } = useLocationsQuery();
  ScrollToTop();
  // useEffect(() => {
  //   return () => {
  //     const resetState = {};
  //     setCheckboxState(resetState);
  //   };
  // }, [setCheckboxState]);

  useEffect(() => {
    if (showSearchModal || showViewModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [showSearchModal, showViewModal]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchModalRef.current &&
        !searchModalRef.current.contains(event.target)
      ) {
        handleCloseSearchModal();
      }
      if (
        viewModalRef.current &&
        !viewModalRef.current.contains(event.target)
      ) {
        handleCloseViewModal();
      }
    };

    if (showSearchModal || showViewModal) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSearchModal, showViewModal]);
  const queryClient = new QueryClient();
  const [activeIndex, setActiveIndex] = useState(null);
  const handleDivClick = (index, location) => {
    setSelectedEnterprise(location);
    setActiveIndex(index);
  };
  return (
    <div className="overflow-hidden relative">
      {showSearchModal && (
        <div className="fixed inset-0 z-50 bg-[#1D428A] bg-opacity-25 flex justify-center items-center">
          <div className="" ref={searchModalRef}>
            <ModalSearch
              checkboxState={checkboxState}
              onCheckboxChange={onCheckboxChange}
              onClose={handleCloseSearchModal}
              onCheckboxChange_Activity={onCheckboxChange_Activity}
              checkboxState_Activity={checkboxState_Activity}
              setCheckboxState={setCheckboxState}
            />
          </div>
        </div>
      )}
      {isLoading && (
        <div className="w-full h-[90vh] flex items-center justify-center">
          <ScaleLoader
            height={40}
            margin={3}
            radius={4}
            width={9}
            color={"#1D428A"}
          />
        </div>
      )}
      {partenaires?.data?.length > 0 ? (
        <div className="testing grid grid-cols-1 md:grid-cols-8 w-full h-full  z-10 overflow-hidden">
          <div className="md:col-span-4 lg:col-span-3 h-full px-">
            <Logo />
            <Search_main
              setSelectedEnterprise={setSelectedEnterprise}
              handleDivClick={handleDivClick}
              onOpenSearchModal={handleOpenSearchModal}
              checkboxState={checkboxState}
              toggleCheckboxState={toggleCheckboxState}
              checkboxState_Activity={checkboxState_Activity}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
            />
          </div>
          <div className="md:col-span-4 lg:col-span-5 h-full z-10">
            <QueryClientProvider client={queryClient}>
              <Map
                latitude={selectedEnterprise?.adresse?.latitude}
                longitude={selectedEnterprise?.adresse?.longitude}
                selectedMarker={activeIndex}
              />
            </QueryClientProvider>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center w-full min-h-screen">
          <Loader />
        </div>
      )}
    </div>
  );
}
