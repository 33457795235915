import { NavLink } from "react-router-dom";
import Bouton from "../Bouton";

const NavbarActions = () => (
  <>
    <div className="flex items-center justify-between px-4 w-full gap-4">
      <NavLink
        to="/salle"
        className="items-center w-1/3 flex py-1 justify-center text-sm font-medium gap-2 bg-main-blue border-2 border-[#1D428A] text-white rounded-lg"
      >
        <img
          src="../img/mouse-pointer.png"
          alt="icon-recherche"
          className="w-[20px]"
        />
        <span className="text-md">Recherche</span>
      </NavLink>

      <NavLink
        to="#"
        onClick={(e) => {
          e.preventDefault();
          window.open(
            "https://calendar.app.google/kGhsrh5DLK98EW6b7",
            "_blank",
            "noopener,noreferrer"
          );
        }}
        className="w-2/3"
      >
        <Bouton
          label="Prenez rendez-vous"
          className="bg-main-red text-white font-semibold rounded-full py-2 text-sm"
        />
      </NavLink>
    </div>
  </>
);

export default NavbarActions;
