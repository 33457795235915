import React from "react";
import NavBar from "./navbar";
import { Footer } from "./Footer";
import { DevenirPartenaireComponent } from "./DevenirPartenaireComponent";
import { CardService } from "./CardService";
import { RechercheSection } from "./RechercheSection";
import { EtrePartenaire } from "./EtrePartenaire";
import LeftSection from "./partenaires/LeftSection";
import RightSection from "./partenaires/RightSection";
import Apropos from "./partenaires/Apropos";
import ListeEntreprises from "./partenaires/ListeEntreprises";
import { usePartenairesQuery } from "../api/partenaires/hooks";
// import { useClientsQuery } from "../api/clients/hooks";

export default function Hero() {
  return (
    <>
      <NavBar />
      <section className="pt-10 max-md:pt-0">
        <div className="hero-content-container w-[90%] mx-auto grid grid-cols-2 max-lg:grid-cols-1 gap-[30px] py-4 pt-12">
          <LeftSection />
          <RightSection />
        </div>
        <AboutComponent />
      </section>
    </>
  );
}

export function AboutComponent() {
  const { data: partenaires = [], isLoading } = usePartenairesQuery();
  // const { data: clients = [], loading } = useClientsQuery();

  return (
    <>
      <Apropos />
      {/* <ListeEntreprises isLoading={loading} clientsData={clients?.data} /> */}
      <EtrePartenaire />
      <DevenirPartenaireComponent />
      <ListeEntreprises isLoading={isLoading} clientsData={partenaires?.data} />
      <CardService />
      <RechercheSection />
      <Footer />
    </>
  );
}
