import React, { useEffect, useState } from "react";
import { apiImgUrl } from "../../http/http";
import Loader from "./Loader";

const Carrousel = ({ logos }) => {
  const datas = logos?.filter((logo) => logo.afficher === 1);

  const shouldScroll = datas?.length > 10;

  const duplicatedLogos = shouldScroll ? [...datas, ...datas] : datas;

  return (
    <div className="carousel-container pt-4 overflow w-full relative">
      <div
        className={`carousel-tracks flex justify-center items-center md:flex-wrap gap-3 ${
          shouldScroll ? "animate-slide carousel-track " : ""
        }`}
        style={{ "--card-gap": "32px" }}
      >
        {duplicatedLogos && duplicatedLogos?.length > 0 ? (
          duplicatedLogos?.map((data, index) => (
            <div
              key={index}
              className="carousel-item w-24 h-24 flex-shrink-0  overflow-hidden"
            >
              <picture>
                <source
                  srcSet={`${apiImgUrl}/storage/${data.logo}`}
                  type="image/webp"
                />
                <img
                  src={`${apiImgUrl}/storage/${data.logoFallback}`}
                  alt={`Logo ${index + 1}`}
                  className="object-contain w-full h-full"
                />
              </picture>
            </div>
          ))
        ) : (
          <p className="text-center py-4 text-gray-500 w-full">
            <Loader />
          </p>
        )}
      </div>
      <style jsx="true">{`
        @media (max-width: 768px) {
          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }
          .carousel-container {
            --card-gap: 32px;
          }
          .carousel-tracks {
            animation: slide 10s linear infinite;
          }
        }
      `}</style>
      {shouldScroll && (
        <style jsx="true">{`
          @keyframes slide {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-50%);
            }
          }

          .carousel-container {
            --card-gap: 32px;
          }

          .carousel-track {
            animation: slide 10s linear infinite;
            display: flex;
            flex-wrap: nowrap;
          }

          @media (max-width: 768px) {
            .carousel-item {
              width: 80px;
              height: 80px;
            }
          }

          @media (max-width: 480px) {
            .carousel-item {
              width: 60px;
              height: 60px;
            }
          }
        `}</style>
      )}
    </div>
  );
};

export default Carrousel;
