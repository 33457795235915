import React from "react";

const AdressClub = ({ infosClub }) => {
  return (
    <div className=" w-[34%] max-md:w-full  ">
      <h1 className="text-h6 mb-0 pb-0 ml-[5.7%] leading-[24px] font-bold font-quicksand -mt-[7.25%] text-black ">
        Adresse
      </h1>
      <div className="bg-main-blue  mb-2 px-4 py-4 rounded-[15px]">
        <h6 className="text-white mb-3 font-quicksand font-semibold text-sm">
          {infosClub?.data?.adresse.rue + " " + infosClub?.data?.adresse.ville}
        </h6>
        <div className="  mt-2 mx-auto rounded-lg">
          <iframe
            width="100%"
            height="100%"
            frameBorder="0"
            style={{ border: 0, borderRadius: 15 }}
            src={`https://www.google.com/maps?q=${infosClub?.data?.adresse.latitude},${infosClub?.data?.adresse.longitude}&hl=es;z=15&output=embed`}
            allowFullScreen
          ></iframe>
        </div>
      </div>
      <h6 className="text-h6  ml-[5.7%] font-bold font-quicksand mt-[2.5%] text-black ">
        Horaires
      </h6>
      <div className=" h-[50px] w-2/3 bg-main-blue mt-[1%] rounded-[10px] flex items-center">
        <svg
          className="ml-[5%]"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 2.6665C14.249 2.6665 12.5152 3.01138 10.8975 3.68144C9.27984 4.35151 7.80998 5.33363 6.57187 6.57175C4.07138 9.07223 2.66663 12.4636 2.66663 15.9998C2.66663 19.5361 4.07138 22.9274 6.57187 25.4279C7.80998 26.666 9.27984 27.6482 10.8975 28.3182C12.5152 28.9883 14.249 29.3332 16 29.3332C19.5362 29.3332 22.9276 27.9284 25.428 25.4279C27.9285 22.9274 29.3333 19.5361 29.3333 15.9998C29.3333 14.2489 28.9884 12.5151 28.3184 10.8974C27.6483 9.27972 26.6662 7.80986 25.428 6.57175C24.1899 5.33363 22.7201 4.35151 21.1024 3.68144C19.4847 3.01138 17.7509 2.6665 16 2.6665ZM21.6 21.5998L14.6666 17.3332V9.33317H16.6666V16.2665L22.6666 19.8665L21.6 21.5998Z"
            fill="white"
          />
        </svg>

        <p className="text-md leading-h5 font-arial ml-[0.5%] font-semibold text-white">
          {infosClub?.data?.horaire ? infosClub?.data?.horaire : "Non defini"}
          {infosClub?.data?.heure_de_fermeture &&
            " à " + infosClub?.data?.heure_de_fermeture}
        </p>
      </div>
    </div>
  );
};

export default AdressClub;
