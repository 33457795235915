import React, { useEffect, useState } from "react";
import { useLocationQuery } from "../api/partenaireSport/hooks";
import { useParams } from "react-router";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import { Modal } from "../components/details-salle-sport/Modal";
import { apiImgUrl } from "../http/http";
import Loader from "../components/partenaires/Loader";
import AdressClub from "../components/details-salle-sport/AdressClub";
import ImagesClub from "../components/details-salle-sport/ImagesClub";
import HeaderClub from "../components/details-salle-sport/HeaderClub";
import { ServiceCard } from "../components/components_search_page/small_components/modal_voir_compo";

export const DetailsClub = () => {
  const { slug } = useParams();
  const { data: infosClub = [], error, isLoading } = useLocationQuery(slug);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [hover, setHover] = useState(true);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isLoading && infosClub?.data) {
      // Gérer les images dans chaque service
      const imageUrls =
        infosClub?.data?.services?.flatMap((service) =>
          service.image.map((img) => `${apiImgUrl}/storage/${img}`)
        ) || [];

      // Si aucun service n'a d'images, utiliser l'image principale du club
      if (imageUrls.length === 0 && infosClub?.data?.image) {
        setImages([`${apiImgUrl}/storage/${infosClub?.data?.image}`]);
      } else {
        setImages(imageUrls);
      }
    } else {
      setImages([]);
    }
  }, [isLoading, infosClub]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  return (
    <>
      {infosClub?.data ? (
        <div className="bg- mx-auto w-[73%] max-md:w-[80%]  max-sm:w-full max-md:px-5 max-lg:px-10 py-9">
          <HeaderClub infosClub={infosClub} />
          <div className="w-full flex gap-6 flex-col md:flex-row justify-between">
            <ImagesClub
              infosClub={infosClub}
              images={images}
              setHover={setHover}
              hover={hover}
              handleImageClick={handleImageClick}
            />
            <AdressClub infosClub={infosClub} />
          </div>
          
          <div className="ml-auto w-full max-md:mb-10 max-md:mt-6 max-md:w-[100%]  bg-white">
            <h5 className="text-h5 leading-h5 font-arial my-3  font-bold text-black ">
              Sports et activités proposés
            </h5>
            {infosClub?.data?.services?.length > 0 ? (
              <div className=" flex w-full bg-re flex-wrap gap-[12px]">
                {infosClub?.data?.services?.map((service, index) => (
                  <ServiceCard service={service} index={index} />
                ))}
              </div>
            ) : (
              <p>Les activites sont indisponible pour le moments</p>
            )}
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-center min-h-screen py-4 text-gray-500 w-full">
          <Loader />
        </div>
      )}
      <Modal
        showModal={open}
        closeModal={closeModal}
        images={images ? images : []}
        selectedImage={selectedImage}
      ></Modal>
    </>
  );
};
