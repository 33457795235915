import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  LayersControl,
  Popup,
  useMap, // Importation de useMap pour manipuler la carte
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useLocationsQuery } from "../../api/partenaireSport/hooks";
import { apiImgUrl } from "../../http/http";
import { ScaleLoader } from "react-spinners";
import { useNavigate } from "react-router";

const SetViewOnChange = ({ positions }) => {
  const map = useMap();
  useEffect(() => {
    map.setView(positions);
  }, [positions, map]);

  return null;
};

export default function Map({
  latitude = 14.6928,
  longitude = -17.4467,
  selectedMarker,
}) {
  const { data: locations = [], error, isLoading } = useLocationsQuery();
  const navigate = useNavigate();

  const defaultPosition = [Number(latitude), Number(longitude)];
  const [positions, setPositions] = useState(defaultPosition);

  useEffect(() => {
    setPositions([Number(latitude), Number(longitude)]);
  }, [latitude, longitude]);

  const getZoom = (locations) => {
    if (!locations || locations.length === 0) return 12;
    if (locations.length > 30) return 10;
    if (locations.length > 5) return 12;
    return 12;
  };

  const zoomLevel = getZoom(locations?.data);

  const customIcon = new L.Icon({
    iconUrl: "./Red-removebg-preview.png",
    iconSize: [30, 40],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
  });

  const selectedIcon = new L.Icon({
    iconUrl: "./location-blue.png",
    iconSize: [15, 25],
    iconAnchor: [17, 45],
    popupAnchor: [0, -45],
  });

  console.log(selectedMarker);

  if (isLoading)
    return (
      <div className="w-full h-[90vh] hidden md:flex items-center justify-center">
        <ScaleLoader
          height={40}
          margin={3}
          radius={4}
          width={9}
          color={"#1D428A"}
        />
      </div>
    );
  if (error) return <div>Erreur de chargement des locations.</div>;
  return (
    <MapContainer
      center={positions}
      zoom={zoomLevel}
      style={{ height: "100vh", width: "100%" }}
    >
      <SetViewOnChange positions={positions} />
      <LayersControl>
        <LayersControl.BaseLayer checked name="Terrain - World Imagery">
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            attribution='<a href="Tiles &copy; Esri">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>
        <LayersControl.BaseLayer checked name="Jawg-Lagoon">
          <TileLayer
            url="https://tile.jawg.io/jawg-lagoon/{z}/{x}/{y}{r}.png?access-token=2U5baWI92SCFF5D9Gp53vRanR2r9g5TQ6X5qhEY4Z0tIQUijlbOEbW2eZmOLGfx9"
            attribution='<a href="https://jawg.io">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </LayersControl.BaseLayer>

        {isLoading
          ? []
          : locations.data.map(
              ({ image, nom, numero, adresse, horaire, slug }, index) => (
                <Marker
                  key={index}
                  position={[
                    adresse.latitude ?? 14.6928,
                    adresse.longitude ?? -17.4467,
                  ]}
                  // icon={customIcon}
                  // icon={selectedIcon}
                  icon={selectedMarker === index ? customIcon : selectedIcon}
                >
                  <Popup>
                    <div
                      className="max-w-[200px] curseur-pointer"
                      onClick={() => navigate(`/salle/${slug}`)}
                    >
                      <div className="w-full h-[100px]">
                        <img
                          className="rounded-lg w-[100%] h-[100%]"
                          src={`${apiImgUrl}/storage/${image}`}
                          alt="image-salle-sport"
                        />
                      </div>
                      <div className="">
                        <div>
                          <h5 className="mt-2 text-sm font-bold tracking-tight">
                            {nom}
                          </h5>
                        </div>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          quartier:{" "}
                          <span className=" font-normal text-gray-700">
                            {numero}
                          </span>
                        </h6>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          Adresse:{" "}
                          <span className=" font-normal text-gray-700">
                            {adresse.quartier}
                          </span>
                        </h6>
                        <h6 className="text-sm font-quicksand text-main-red font-semibold">
                          Horaires:{" "}
                          <span className=" font-normal text-gray-700">
                            {horaire}
                          </span>
                        </h6>
                      </div>
                    </div>
                  </Popup>
                </Marker>
              )
            )}
      </LayersControl>
    </MapContainer>
  );
}
