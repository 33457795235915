import React, { useState } from "react";
import ActivityBox from "./activity_box";
import Bouton from "./Bouton";
import { Link } from "react-router-dom";
import { apiImgUrl } from "../http/http";
import { useActivitesQuery } from "../api/activities/hooks";

export default function Activities() {
  const { data: activities = [], error, isLoading } = useActivitesQuery();

  const [useStaticImage, setUseStaticImage] = useState(false);
  const [showAllActivities, setShowAllActivities] = useState(false);

  const getFallbackData = [
    { backgroundImage: "../img/activities/badminton.jpg", text: "badminton" },
    {
      backgroundImage: "/img/activities/pexels-pixabay-46798.jpg",
      text: "football",
    },
    { backgroundImage: "/img/activities/blackman2.png", text: "musculation" },
    { backgroundImage: "/img/activities/yoga1.png", text: "yoga" },
    { backgroundImage: "/img/activities/mma.png", text: "mma" },
    { backgroundImage: "/img/activities/boxe.png", text: "boxe" },
    { backgroundImage: "/img/activities/swim.png", text: "natation" },
    { backgroundImage: "/img/activities/jjk.png", text: "Jiujitsu brésilien" },
    { backgroundImage: "/img/activities/fitness.png", text: "fitness" },
    { backgroundImage: "/img/activities/gym.png", text: "gym" },
    { backgroundImage: "/img/activities/karate.png", text: "karaté" },
    { backgroundImage: "/img/activities/muaythai.png", text: "muay thai" },
  ];
  const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
      result.push(array.slice(i, i + size));
    }
    return result;
  };

  const handleShowAllActivities = () => {
    if (activities?.length > 0) {
      setShowAllActivities(!showAllActivities);
    }
  };

  const rows = chunkArray(activities || [], 6);
  console.log(rows[1]);

  return (
    <>
      <div className="Activity_container mt-0 pt-0   ">
        <div className="dots_n_activities w-[100%] pt-10 bg-dots-active bg-cover bg-center flex flex-col  ">
          <h1 className="activity_heading text-h3  text-main-blue text-center font-bold font-arial max-sm:mb-[10%] max-lg:text-h4 max-lg:w-[95%] max-lg:mx-auto max-ml:text-h4 max-ml:leading-h4  max-sm:text-h5 max-sm:leading-h5">
            Nos Activités: conçus pour chaque objectif
          </h1>
          {showAllActivities ? (
            <div className=" grid grid-cols-1 mt-[3.7%] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-9 md:ps-0  gap-5 w-full md:w-[90%] pe-4 animate-fadeIn  mx-auto">
              {activities?.length > 0 &&
                activities.map((activity, index) => (
                  <div className="h-[200px]  animate-slideUp opacity-100 transition-opacity duration-300 delay-[index * 100]">
                    <Link to="/salle">
                      <div
                        key={index}
                        className="z-0 rounded-[15px] h-full w-full flex items-center cursor-pointer "
                        style={{
                          backgroundImage: `url(${
                            useStaticImage
                              ? activity.backgroundImage
                              : `${apiImgUrl}/storage/${activity.image}`
                          })`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                        }}
                      >
                        <div className="relative flex items-center justify-center w-full h-[50px]">
                          <div className="absolute inset-0 backdrop-blur-[40px] bg-black mix-blend-overlay opacity-40" />
                          <div className="relative z-10 flex items-center justify-center w-full h-[50px]">
                            <h2 className="text-white font-r-mono font-bold text-[20px] mt-1 leading-[32px] text-center uppercase">
                              {activity.nom || "Default Text"}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
            </div>
          ) : (
            <>
              {activities?.length > 0 ? (
                <>
                  {" "}
                  {rows[0] && (
                    <div className="relative w-[100vw] pl-20 max-sm:pl-8 h-[200px] mt-[3.7%] overflow-x-scroll overflow-y-hidden scrollbar-hide max-sm:mb-[5%]  ">
                      <div className="activity_row1 flex ml-[0.4%] h-[100%] w-[1650px] max-sm:w-[1780px] overflow-hidden rounded-[15px] z-0 gap-[30px] ">
                        {rows[0] &&
                          rows[0].map((activity, index) => (
                            <ActivityBox
                              key={index}
                              backgroundImage={
                                useStaticImage
                                  ? activity.backgroundImage
                                  : `${apiImgUrl}/storage/${activity.image}`
                              }
                              text={activity.nom || "Default Text"}
                            />
                          ))}
                      </div>
                    </div>
                  )}
                  {rows[1] && (
                    <div className="relative w-[100vw] pl-20 max-sm:pl-8 h-[200px] mt-[3.7%] overflow-x-scroll overflow-y-hidden scrollbar-hide max-sm:mb-[5%]  ">
                      <div className="activity_row1 flex ml-[0.4%] h-[100%] w-[1650px] max-sm:w-[1780px] overflow-hidden rounded-[15px] z-0 gap-[30px] ">
                        {rows[1].map((activity, index) => (
                          <ActivityBox
                            key={index}
                            backgroundImage={
                              useStaticImage
                                ? activity.backgroundImage
                                : `${apiImgUrl}/storage/${activity.image}`
                            }
                            text={activity.nom || "Default Text"}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <p className="text-center py-8 text-gray w-full">
                  Aucune Activités disponible pour le moment.
                </p>
              )}
            </>
          )}
        </div>
      </div>
      <div className=" mt-9 mx-auto flex items-center justify-center">
        <Bouton
          label={showAllActivities ? "Masquer" : "Voir Toutes les Activités"}
          onClick={handleShowAllActivities}
          className="bg-main-red font-semibold py-2 hover:text-main-red hover:bg-white hover:border-2 hover:border-[#C8102E] text-h6  font-r-mono text-white text-center text-sm transition-all duration-500 ease-in-out rounded-full "
        />
      </div>
    </>
  );
}
