import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Card = ({ title, description, onClick }) => (
  <div
    onClick={onClick}
    className="flex cursor-pointer justify-between items-center w-full px-4 py-3 h-auto md:h-[90px] bg-white border-2 border-red-700 hover:opacity-90 text-black rounded-md shadow transition duration-200"
  >
    <div className="w-full md:w-3/4">
      <p className="font-bold text-[14px] md:text-sm">{title}</p>
      <p className="text-[12px] hidden md:block">{description}</p>
    </div>
    <button className="text-black">
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  </div>
);

const CardsPartenairesListe = ({
  setShowModal,
  showModal,
  setShowModalTwo,
  showModalTwo,
  setShowModalThree,
  showModalThree,
}) => {
  const cards = [
    {
      title: "Au Seins de mon entreprise, je suis responsable",
      description:
        "Ressources humaines, membre de la direction générale, directeur de département, etc.",
      onClick: () => setShowModal(!showModal),
    },
    {
      title: "Je suis un salarié d’une entreprise",
      description:
        "Porteur(se) de projet pour la solution Ket Ket dans mon entreprise",
      onClick: () => setShowModalTwo(!showModalTwo),
    },
    {
      title: "Je suis gérant d’une infrastructure sport et/ou bien-être",
      description:
        "Propriétaire d’une salle de sports, je suis un enseignant qui dirige des cours sportifs, etc.",
      onClick: () => setShowModalThree(!showModalThree),
    },
  ];

  return (
    <div className="space-y-4">
      {cards.map((card, index) => (
        <Card
          key={index}
          title={card.title}
          description={card.description}
          onClick={card.onClick}
        />
      ))}
    </div>
  );
};

export default CardsPartenairesListe;
