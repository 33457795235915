import React from "react";
import { useClientsQuery } from "../../api/clients/hooks";
import Loader from "./Loader";
// import Logos from "./Logos";
import Carrousel from "./Carrousel";
import Logos from "../partenaire_logos";

const ListeEntreprises = ({ clientsData, isLoading }) => {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="flex flex-col gap-[10%] w-[90%] pe-4 max-sm:w-full max-sm:px-5 mx-auto pt-5 text-">
          <h4 className="md:text-h4 text-sm text-center leading-[39px] font-bold font-quicksand">
            Ils nous font confiance
          </h4>
          <Carrousel logos={clientsData} />
        </div>
      )}
    </>
  );
};

export default ListeEntreprises;
