import React from "react";

export const Modal = ({ showModal, closeModal, title, text, children }) => {
  return showModal ? (
    <div
      onClick={closeModal}
      className="fixed flex  place-items-centermd:items-center md:justify-center inset-0 z-50  w-full h-full scrollbar-hide  overflow-auto bg-[#1D428A] bg-opacity-25"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white font-[Quicksand] rounded-lg sm:max-h-[90%] md:min-h-screen overflow-auto scrollbar-hide border-2 border-blue-50 shadow-md mx-auto p-1 w-[100%]  md:w-2/3 lg:w-1/2"
      >
        <div className="flex justify-end items-end px-5">
          <button onClick={closeModal} className="font-medium text-lg">
            X
          </button>
        </div>
        <div className="w-2/3 px-5">
          <h6 className="font-bold font-[Quicksand] text-[12px] md:text-[20px] leading-6">
            {title}
          </h6>
          <p className="text-[11.5px] md:text-[12px] pt-1 font-semibold">
            Afin que notre équipe puisse vous contacter à temps
          </p>
        </div>
        {children}
      </div>
    </div>
  ) : null;
};
