import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMousePointer, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Bouton from "./Bouton";

export default function Hero() {
  return (
    <div className="py-6">
      <div className=" w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5  flex justify-between mt-2 max-sm:mt-[0px] max-lg:flex-wrap">
        <div className="hero-text-container flex-col">
          <div className="hero-text bg-white">
            <div
              className="hero-title bg-hero-texture py-4 bg-no-repeat ;"
              style={{ backgroundSize: "400px" }}
            >
              <h1 className="font-Arial font-bold text-[60px] max-sm:text-[40px]  max-sm:leading-[50px] text-main-blue max-w-sm ">
                WELLNESS AT WORK
              </h1>
            </div>
          </div>
          <div className="hero-subtitle py-2">
            <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold my-2 max-sm:my-0 ">
              Rejoignez-nous et transformez votre forme physique dès
              aujourd'hui.
            </p>
            <p className="text-h6 text-sm font-quicksand leading-[20px] font-semibold my-4  ">
              Des installations de pointe et des cours diversifiés vous
              attendent.
            </p>
          </div>

          <div className="flex-col-reverse md:flex   md:flex-row my-10 gap-4 max-sm:mt-3">
            <Link to={"/salle"}>
              <Bouton
                icon={
                  <FontAwesomeIcon
                    icon={faMousePointer}
                    className="max-sm:hidden"
                  />
                }
                label={"Rechercher une salle ou une activité"}
                className={
                  "bg-main-blue text-white text-sm p-2 max-md:text-sm max-sm:text-sm mb-3 md:mb-0"
                }
              />
            </Link>
            <Link to={"/partenaires/devenir-partenaire"}>
              <Bouton
                icon={
                  <FontAwesomeIcon icon={faUser} className="max-sm:hidden" />
                }
                label={"Devenons partenaires"}
                className={
                  "bg-white text-black text-[13px] border p-2 border-gray-300 max-md:text-sm max-sm:text-sm"
                }
              />
            </Link>
          </div>

          <div className="accolades flex gap-[1%] items-center justify-between xl:mt-16 2xl:mt-20 flex-nowrap max-md:flex-wrap max-lg:h-auto mt-4">
            {/* Premier award */}
            <div className="award flex items-center gap-[5%]">
              <img
                className="h-[40px] w-[40px]"
                src={"../img/award1.png"}
                alt="award-icon-1"
                 loading="lazy"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                EXCEPTIONNEL COMMENTAIRES
              </div>
            </div>

            {/* Deuxième award */}
            <div className="award flex items-center gap-[5%] py-5 ">
              <img
                className="h-[40px] w-[40px]"
                src={"../img/award2.png"}
                alt="award-icon-2"
                 loading="lazy"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                ENTRAÎNEURS DE CLASSE MONDIALE
              </div>
            </div>

            {/* Troisième award */}
            <div className="award flex items-center gap-[5%]">
              <img
                className="h-[40px] w-[40px]"
                src={"../img/award3.png"}
                alt="award-icon-3"
                 loading="lazy"
              />
              <div className=" leading-2 text-xs xl:text-[13px] 2xl:text-[15px] font-arial font-bold">
                INSTALLATIONS EXTENSIVES
              </div>
            </div>
          </div>
        </div>

        {/**-------------l'image de droite---------------- */}
        <div className="hero-image-container max-lg:w-[100%] h-[340px] xl:h-[400px] 2xl:h-[500px] w-[40%] my-8">
          <img
            src={"../img/hero-image.webp"}
            alt="hero"
            fetchpriority="high"
            className="w-[100%] relative h-[100%] object-cover rounded-lg shadow-lg"
          />
          <div className="h-[5%] -mt-[20%]">
            <img
              className="ml-[72.5%] w-[35%]"
              src="../img/red-circle.png"
              alt="icon-red"
               loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
