import React from "react";
import NavBar from "./navbar";
import { Modal } from "./Formulaires/Modal";
import Form from "./Formulaires/Form";
import { FormPartenaire } from "./Formulaires/FormPartenaire";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useContacts from "../utils/hooks/recherche/useContacts";
import FormSportPartenaire from "./Formulaires/FormSportPartenaire";
import FormEntreprise from "./Formulaires/FormEntreprise";
import PartnerSectionLeft from "./PartnaireSectionLeft";
import PartnerSectionRight from "./PartnerSectionRight";

const DevenirPartenaire = () => {
  const {
    showModal,
    showModalTwo,
    showModalThree,
    setShowModal,
    setShowModalTwo,
    setShowModalThree,
    numero,
    setNumero,
    isLoading,
    formState,
    setFormState,
    handleChange,
    handleSubmit,
  } = useContacts();

  return (
    <div className="">
      <NavBar />
      <div className="bg- w-full mx-auto  space-y-6">
        <div className="pt-6 md:pt-12  grid grid-cols-1 lg:grid-cols-2 ">
          <PartnerSectionLeft />
          <PartnerSectionRight />
        </div>
      </div>
      <Modal
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        title="Financer la pratique sportive de vos collaborateurs"
      >
        <FormEntreprise />
      </Modal>
      <Modal
        showModal={showModalThree}
        closeModal={() => setShowModalThree(false)}
        title="Devenez Notre Partenaire"
      >
        <Form
          onSubmit={handleSubmit}
          FormContainer={
            <FormPartenaire
              formState={formState}
              handleChange={handleChange}
              setPhone={setNumero}
              phone={numero}
              setFormState={setFormState}
            />
          }
          isLoading={isLoading}
        />
      </Modal>
      <Modal
        showModal={showModalTwo}
        closeModal={() => setShowModalTwo(false)}
        title="Faites financer vos activités sportives"
      >
        <FormSportPartenaire />
      </Modal>
      <ToastContainer />
    </div>
  );
};

export default DevenirPartenaire;
