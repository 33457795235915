import React from "react";
import { apiImgUrl } from "../../http/http";

const HoverImage = ({ images, infosClub, handleImageClick, hover }) => {
  return (
    <>
      {hover ? null : (
        <div
          onClick={(e) => e.stopPropagation()}
          className="animate-slideUp flex h-[130px] z-10 w-[100%] inset-0 backdrop-blur-2xl bg-opacity-10 rounded-b-[5px] mt-auto bg-black pl-5 gap-5 items-center border ease-in-out delay-300 border-[#D4D4D4] border-opacity-30"
        >
          {images.length > 0 ? (
            images?.slice(0, 4).map((image, index) => (
              <div
                key={index}
                onClick={() => handleImageClick(`${image}`)}
                className="bg-slate-300 w-[120px] h-[80px]"
                style={{
                  backgroundImage: `url(${image})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              ></div>
            ))
          ) : (
            <div
              onClick={() =>
                handleImageClick(
                  `${apiImgUrl}/storage/${infosClub?.data?.image}`
                )
              }
              className="bg-slate-300 w-[120px] h-[80px]"
              style={{
                backgroundImage: `url(${apiImgUrl}/storage/${infosClub?.data?.image})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            ></div>
          )}
        </div>
      )}
    </>
  );
};

export default HoverImage;
