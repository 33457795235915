import React from "react";
import LeftSection from "../components/partenaires/LeftSection";
import RightSection from "../components/partenaires/RightSection";
import ContainerComponent from "../components/ContainerComponent";
import { AboutComponent } from "../components/partenaire";

const Partenaires = () => {
  return (
    <ContainerComponent>
      <div className="grid grid-cols-2 pb-3 w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5 max-lg:grid-cols-1 gap-[30px] py-4 pt-3 md:pt-8">
        <LeftSection />
        <RightSection />
      </div>
      <AboutComponent />
    </ContainerComponent>
  );
};

export default Partenaires;
