import React, { useRef } from "react";

export default function Service() {
  const scrollContainerRef = useRef(null);

  const fallbackImages = [
    "/img/service/service1.png",
    "/img/service/service2.png",
    "/img/service/service4.png",
    "/img/service/service3.png",
  ];

  const services = [
    {
      image: fallbackImages[0],
      subtitle: "Accès au sport pour tous:",
      description:
        "Ket Ket s'engage à faciliter l'accès des salariés à des structures sportives amateurs de qualité. En rendant le sport plus accessible, l'entreprise encourage une pratique régulière et contribue ainsi à améliorer la santé physique et mentale des employés.",
    },
    {
      image: fallbackImages[1],
      subtitle: "Cohésion d'équipe renforcée",
      description:
        "La pratique sportive en entreprise peut être un excellent moyen de renforcer la cohésion d'équipe. Ket Ket propose des solutions d'adhésion qui permettent aux salariés de partager des moments conviviaux et de développer des liens solides en dehors du cadre professionnel.",
    },
    {
      image: fallbackImages[2],
      subtitle: "Promotion d'un mode de vie actif",
      description:
        "Ket Ket encourage les entreprises à soutenir et à financer la pratique sportive de leurs employés. En mettant l'accent sur un mode de vie actif, l'entreprise vise à réduire le stress, favoriser le bien-être mental et renforcer le lien social au sein de l'entreprise.",
    },
    {
      image: fallbackImages[3],
      subtitle: "Promotion de la diversité et de l'inclusion",
      description:
        "En rendant le sport accessible à tous les employés, Ket Ket favorise la diversité et l'inclusion, permettant à chacun de s'épanouir dans un environnement inclusif.",
    },
  ];

  const bgClasses = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const bgClassesVertical = [
    "bg-main-blue",
    "bg-service-2",
    "bg-service-3",
    "bg-main-red",
  ];

  const getBgClass = (index) => {
    return bgClasses[index % bgClasses.length];
  };

  const getBgClassVertical = (index) => {
    return bgClassesVertical[index % bgClassesVertical.length];
  };

  return (
    <>
      <div className="service-container">
        <div className="service-title relative z-40 h-[50.8%]  w-[100%] bottom-[20%]  flex place-content-evenly ">
          <div className="squibbly-containr w-[24%] h-[100%] max-lg:hidden">
            <div className="squib ml-[23%] mt-[12.5%] max-ml:mt-[12.5%]">
              <img
                className="w-[36%] rotate-[0deg]"
                src="../img/blue-vector.png"
                alt=""
              />
            </div>
          </div>
          <div className="relative w-[51%] m-auto  grid place-items-center  max-lg:w-[86%] max-sm:w-[100%]">
            <div className="text-center w-[90%] mt-[4.3%] font-arial font-bold text-sm md:text-h5 max-ml:w-[100%] max-sm:w-[95%] leading-h3 text-main-black max-ml:leading-h4 max-sm:leading-h5">
              Transformez votre entreprise avec les solutions sportives de Ket
              Ket
            </div>
          </div>
          <div className="squibbly-containr w-[24%] h-[100%] max-lg:hidden">
            <div className="squib ml-[45%] mt-[12.5%] max-ml:mt-[12.5%]">
              <img
                className="w-[45%] -rotate-[30deg]"
                src="../img/blue-vector.png"
                alt=""
              />
            </div>
          </div>
        </div>

        <div className="service-main-container w-[90vw] flex justify-center items-center mx-auto ">
          <section className="hidescrollbar md:pe-4 pb-5" ref={scrollContainerRef}>
            <div className="service-main-content py-8 relative mx-auto  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-5  z-30">
              {services.map((service, index) => {
                const width = window.innerWidth;
                const bgClass =
                  width < 1025 ? getBgClassVertical(index) : getBgClass(index);
                return (
                  <div
                    key={index}
                    className="relative w-full text-medium flex-shrink-0 bg-white z-50 rounded-[12px]  shadow-service-shade flex flex-col "
                  >
                    <div
                      className={`card-top ${bgClass} relative h-[50px] rounded-t-[12px] z-30`}
                    >
                      <div className="rounded-full h-[64px] w-[64px] relative z-40  bg-white ml-[73.5%] mt-[5.1%] flex items-center justify-center">
                        <img
                          src={fallbackImages[index % fallbackImages.length]}
                          alt={`Image for ${service.subtitle}`}
                          className="w-[32px] h-[32px]"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col gap-[10px] relative z-50">
                      <h6 className=" text-[#ca1935] text-h6 text-sm  ml-[5.15%] w-[78%] h-14 pt-[7.6%] font-bold">
                        {service.subtitle}
                      </h6>
                      <p className=" font-arial text-h6 text-sm ml-[5.15%] w-[93%] -leading-[25px] mb-2">
                        {service.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
