import React from "react";
import { apiImgUrl } from "../../http/http";
import { ServiceCard } from "../components_search_page/small_components/modal_voir_compo";
import HoverImage from "./HoverImage";

const ImagesClub = ({
  infosClub,
  images,
  setHover,
  handleImageClick,
  hover,
}) => {
  return (
    <div className="w-[63%] rounded-sm max-md:w-full max-md:mb-5">
      <div
        className="w-[100%] z-10 h-[300px] cursor-pointer flex rounded-[5px]"
        onMouseEnter={() => setHover(false)}
        onMouseLeave={() => setHover(true)}
        onClick={() =>
          handleImageClick(
            infosClub?.data?.services?.[0]?.image
              ? `${apiImgUrl}/storage/${infosClub?.data?.services[0]?.image}`
              : `${apiImgUrl}/storage/${infosClub?.data?.image}`
          )
        }
        style={{
          backgroundSize: "cover",
          backgroundImage: `url(${
            infosClub?.data?.services?.[0]?.image
              ? `${apiImgUrl}/storage/${infosClub?.data?.services[0]?.image[0]}`
              : `${apiImgUrl}/storage/${infosClub?.data?.image}`
          })`,
          backgroundPosition: "center",
        }}
      >
        <HoverImage
          images={images}
          infosClub={infosClub}
          handleImageClick={handleImageClick}
          hover={hover}
        />
      </div>
      <div className="text-h6 text-sm leading-p h-auto w-full mt-[3%] font-quicksand font-semibold  z-20">
        {infosClub?.data?.description}
      </div>
    </div>
  );
};

export default ImagesClub;
