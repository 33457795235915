import React from "react";
import { apiImgUrl } from "../../../http/http";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function Voir_tag({ title, key, prix, logo }) {
  return (
    <>
      <div
        key={key}
        className=" flex-shrink-0 flex-grow-0 h-[104.5px] w-[155px] border-2 rounded-[12px] border-[#A5A5A5] "
      >
        {logo && (
          <img
            src={`${apiImgUrl}/storage/${logo}`}
            alt="Logo"
            className="ml-[12%] mt-[10%] w-[32px] h-[32px]"
          />
        )}
        <div className="w-[115px] text-h6 text-sm leading-p mt-[5px] font-semibold text-black font-quicksand m-auto">
          {title}
        </div>

        <p className="w-[115px] text-[17px] pb-2  text-sm leading-p mt-[5px] font-semibold text-black/60 font-quicksand m-auto">
          {prix} F
        </p>
      </div>
    </>
  );
}

export const ServiceCard = ({ service, index }) => {
  return (
    <div
      key={index}
      className="w-full md:w-[220px] rounded-lg border border-e-4 mb-5 shadow-lg bg-white overflow-hidden"
    >
      <div className=" items-center ">
        <div className="border-b flex items-center justify-between px-3 border-gray-200">
          <h2 className="font-bold text-md capitalize text-gray-800">
            {service.activite.nom}
          </h2>
          {service?.icon_image ? (
            <img
              src={`${apiImgUrl}/storage/${service.icon_image}`}
              alt={service.nom}
              className="w-10 h-10 rounded-full border-2 border-gray-200 shadow-sm"
            />
          ) : (
            <FontAwesomeIcon className="fa-2x" icon={faRunning} />
          )}
        </div>
        <div className="ml- p-3 px-2">
          <p className="text-sm text-gray-600">
            Prix:{" "}
            <span className="text-gray-800 font-medium">
              {service.modalite.prix} FCFA
            </span>
          </p>
          <p className="text-sm text-gray-600">
            Inscription:{" "}
            <span className="text-gray-800 font-medium">
              {service.modalite.inscription} FCFA
            </span>
          </p>
         
          <p className="text-sm text-gray-600">
            Horaires:{" "}
            <span className="text-gray-800 font-medium">{service.horaire}</span>
          </p>
          <p className="text-sm text-gray-600">
            Jours:{" "}
            <span className="text-gray-800 font-medium">
              {Array.isArray(service.jours)
                ? service.jours.join(", ")
                : "Non spécifié"}
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};
