import React from "react";
import CardsPartenairesListe from "./CardsPartenairesListe";
import useContacts from "../utils/hooks/recherche/useContacts";

const PartnerSectionRight = () => {
  const {
    showModal,
    showModalTwo,
    showModalThree,
    setShowModal,
    setShowModalTwo,
    setShowModalThree,
  } = useContacts();
  return (
    <div
      style={{ backgroundImage: `url("/Dot-Grid.png")` }}
      className="col-span-1 dots_n_activities py-8 md:py-16  bg-cover bg-center flex items-center  justify-center"
    >
      <div className="bg-[#1D428A]  text-white px-5 py-4 w-[90%] md:w-3/4 rounded-lg">
        <h2 className="text-2xl font-semibold text-white text-center pb-5 pt-2">
          Qui êtes-vous?
        </h2>
        <CardsPartenairesListe
          setShowModal={setShowModal}
          showModal={showModal}
          setShowModalTwo={setShowModalTwo}
          showModalTwo={showModalTwo}
          setShowModalThree={setShowModalThree}
          showModalThree={showModalThree}
        />
      </div>
    </div>
  );
};

export default PartnerSectionRight;
