import React from "react";
import { NavLink } from "react-router-dom";

const FollowUs = ({ datas }) => {
  return (
    <div className="text- pt-3  mb-3 flex items-center gap-6 ">
      {datas?.map((data, index) => (
        <NavLink
          key={index}
          to="#"
          onClick={(e) => {
            e.preventDefault(); 
            window.open(data.link, "_blank", "noopener,noreferrer"); 
          }}
          className="hover:text-slate-800 space-x- text-gray-500 font-semibold text-sm"
        >
          <span>{data.icon}</span>
        </NavLink>
      ))}
    </div>
  );
};

export default FollowUs;
