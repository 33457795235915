import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const HeaderClub = ({ infosClub }) => {
  return (
    <>
      <div className="flex justify-end gap-4 ">
        <Link
          to={"/salle"}
          className={
            "bg-main-blue text-white  text-sm   rounded-full flex items-center px-2.5 py-2 gap-2 "
          }
        >
          <FontAwesomeIcon icon={faXmark} />
        </Link>
      </div>
      <h4 className="font-quicksand space-x-3 font-bold text-4xl max-sm:text-sm">
        {infosClub?.data?.nom} -{" "}
        <span className="font-medium font-quicksand capitalize">
          {infosClub?.data?.adresse.ville}, {infosClub?.data?.adresse.quartier}
        </span>
      </h4>
      <div className="tags w-full h-[60px]  mt-auto flex items-center gap-[15px]">
        <div className="px-3 py-1.5 rounded-[5px] bg-main-red flex items-center justify-center ">
          <p className="text-center leading-p text-sm font-bold align-middle  text-white font-quicksand capitalize">
            {infosClub?.data?.categorie?.nom}
          </p>
        </div>
        <div className="bg-main-blue px-3 py-1.5 rounded-[5px] flex items-center justify-center ">
          <p className="text-center text-sm leading-p font-bold text-white align-middle font-quicksand capitalize">
            {infosClub?.data?.numero}
          </p>
        </div>
      </div>
    </>
  );
};

export default HeaderClub;
