import React from "react";

const PartnerSectionLeft = () => {
  const data = {
    highlight: "Devenons",
    title: "Partenaire",
    description:
      "Prenez un rendez-vous, afin que nous puissions vous contacter et vous donner plus d’informations.",
    listItems: [
      "Nous sommes partenaires de plus de 5 salles de sport",
      "Nous offrons à nos clients l’accès à plusieurs activités, et nous prévoyons d’augmenter le nombre d’activités",
      "Nous offrons des solutions adaptées aux besoins des employés",
    ],
  };

  return (
    <div className="col-span-1 p-4 py-6 pt-10 sm:p-8 md:p-14 ">
      <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold text-red-600">
        <span className="text-black">{data?.highlight}</span> {data?.title}
      </h1>
      <p className="text-lg sm:text-xl md:text-2xl lg:text-3xl text-[#254385] font-medium py-3 md:py-7">
        {data.description}
      </p>
      <ul className="list-disc space-y-2 custom-marker font-medium text-sm leading-h5">
        {data.listItems.map((item, index) => (
          <li key={index} className="text-[14px] md:leading-[35px]">
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PartnerSectionLeft;
