import React from "react";
import Hero from "../components/hero";
import Apropos from "../components/partenaires/Apropos";
import Service from "../components/service";
import Activities from "../components/activity";
import { Footer } from "../components/Footer";
import ListeEntreprises from "../components/partenaires/ListeEntreprises";
import { useClientsQuery } from "../api/clients/hooks";
import { CardService } from "../components/CardService";
import { usePartenairesQuery } from "../api/partenaires/hooks";

const Homepage = () => {
  // const { data: clients = [], isLoading } = useClientsQuery();
  const { data: partenaires = [], isLoading } = usePartenairesQuery();

  return (
    <section className=" max-md:pt-0">
      <Hero />
      <Apropos />
      <div className="py-10 max-md:py-0">
        {/*<ListeEntreprises isLoading={isLoading} clientsData={clients?.data} />*/}
        <ListeEntreprises
          isLoading={isLoading}
          clientsData={partenaires?.data}
        />
      </div>
      <CardService />
      <Service />
      <div className="mb-5">
        <Activities />
      </div>
      
      <Footer />
    </section>
  );
};

export default Homepage;
