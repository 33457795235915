import React from "react";
import {
  AccesSports,
  BienEtreDeveloppement,
  ImageStyle,
  PourquoiKetKet,
} from "./About";

const Apropos = ({ about }) => {
  return (
    <div className=" mt-3 mb-11 w-[90%] mx-auto pe-4 max-sm:w-full max-sm:px-5 max-sm:mt-8">
      <div className=" h-[100%]   relative  mt-[5.1%] ">
        <ImageStyle />
        <PourquoiKetKet about={about} />
        <AccesSports about={about} />
        <BienEtreDeveloppement about={about} />
      </div>
    </div>
  );
};

export default Apropos;
